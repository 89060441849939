.loader {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: none;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: #ffffff9e;
  z-index: 1111111111111111;
}
.loader.in {
  display: flex;
}
.loader .main-spin {
  width: 70px;
  height: 70px;
  border-color: #70b0c1;
  border-right-color: transparent;
}

.blink-soft {
  animation: blinker 1.6s linear infinite;
  color: #696158;
  font-weight: 500;
  border-color: #696158;
}
@keyframes blinker {
  50% {
    opacity: 0;
  }
}

input.form-control.form-input.blink-soft {
  animation: blinker 1.6s linear infinite;
  font-weight: 500;
  border: 1px solid #696158;
  color: #696158;
}
input.form-control.form-date.blink-soft::placeholder {
  color: #696158;
}
.form-date.blink-soft {
  border: 1px solid #696158;
  color: #696158;
}
